<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="tableHeaders"
            :items="tableContent"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> 可选时间段/Available Schedule </v-toolbar-title>

                <v-dialog persistent v-model="addPresetDialog" max-width="480px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Add </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">添加可选时间/New Schedule Preset</span>
                    </v-card-title>
                    <v-card-subtitle>
                      选择起止时间，会自动按每场次20分钟，每3场间空5分钟填满场次
                    </v-card-subtitle>

                    <v-card-text>
                      <v-text-field :counter="100" v-model="newLocation" label="地点/Location">
                      </v-text-field>

                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newPresetDate"
                            label="选择日期/Select Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="newPresetDate"
                          @input="datePicker = false"
                        ></v-date-picker>
                      </v-menu>

                      <v-menu
                        v-model="startTimePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newPresetStartTime"
                            label="选择开始时间/Select Start Time"
                            prepend-icon="mdi-clock"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="newPresetStartTime"
                          @input="startTimePicker = false"
                          format="24hr"
                        ></v-time-picker>
                      </v-menu>

                      <v-menu
                        v-model="endTimePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newPresetEndTime"
                            label="选择结束时间/Select End Time"
                            prepend-icon="mdi-clock"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="newPresetEndTime"
                          @input="endTimePicker = false"
                          format="24hr"
                        ></v-time-picker>
                      </v-menu>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="presetAddClose"> Cancel </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="presetAddConfirm"
                        :disabled="!newPresetDate || !newPresetStartTime || !newPresetEndTime"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <span class="ma-2">
                  <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn v-else @click="exportSchedule()">
                    <v-icon>mdi-archive</v-icon> 导出预约列表
                  </v-btn>
                </span>

                <v-dialog persistent v-model="deletePresetDialog" max-width="640px">
                  <v-card>
                    <v-card-title class="headline">删除时间段</v-card-title>
                    <v-card-subtitle>
                      {{ deletePreset.time }} ~ {{ deletePreset.location }}
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="presetDeleteClose">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="presetDeleteConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>

            <v-spacer></v-spacer>

            <template v-for="professor in professors" v-slot:[`header.${professor.id}`]>
              <v-chip :key="professor.id" outlined small @click="viewPost(professor)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <template v-if="professor.schedule_config">
                        <v-icon left>{{
                          professor.schedule_config.one_to_many
                            ? "mdi-account-group"
                            : "mdi-account"
                        }}</v-icon>
                      </template>
                      {{ professor.name }}
                    </span>
                  </template>
                  <span>下载海报/Download Post</span>
                </v-tooltip>
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="handleDeletePreset(item)"> mdi-delete </v-icon>
            </template>

            <template v-for="professor in professors" v-slot:[`item.${professor.id}`]="{ item }">
              <template v-if="item[professor.id]">
                <template v-if="item[professor.id].attendees.length > 0">
                  <v-menu :key="professor.id" open-on-hover bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small color="primary lighten-1" dark v-bind="attrs" v-on="on">
                        {{ item[professor.id].attendees.length }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(student, index) in item[professor.id].attendees"
                        :key="index"
                        router
                        :to="`/review/detail/${student.camp_id}`"
                        target="_blank"
                      >
                        <v-list-item-title>{{ student.desc }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-else>
                  <v-btn :key="professor.id" small color="grey lighten-1" dark> 0 </v-btn>
                </template>
              </template>
            </template>

            <template v-slot:[`item.schedules`]="{ item }">
              <v-chip small v-for="schedule in item.schedules" :key="schedule.id">
                {{ schedule.professor.name }}({{ schedule.location }})</v-chip
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import requests from "@/http";
import mimeDB from "mime-db";

export default {
  data: () => ({
    search: "",
    waiting: false,
    newPresetDate: null,
    newPresetStartTime: null,
    newPresetEndTime: null,
    newLocation: "",
    addPresetDialog: false,
    deletePresetDialog: false,
    datePicker: false,
    startTimePicker: false,
    endTimePicker: false,
    tableHeaders: [],
    tableContent: [],
    professors: [],

    deletePreset: {},
    presets: [],
  }),
  created() {
    this.getAllSchedulePreset();
  },
  methods: {
    getAllSchedulePreset() {
      requests
        .get(`/api/admin/schedule/preset`)
        .then((res) => {
          const presets = res.data.presets;
          const professors = res.data.professors;
          this.professors = professors;

          this.tableHeaders = [
            { text: "时间/Time", align: "start", value: "time", width: "160px" },
            { text: "地点/Location", align: "start", value: "location", width: "128px" },
            { text: "操作/Actions", sortable: false, value: "actions", width: "104px" },
          ];
          professors.forEach((pro) => {
            this.tableHeaders.push({
              text: pro,
              align: "center",
              value: String(pro.id),
              sortable: false,
            });
          });

          this.tableContent = [];
          presets.forEach((pre) => {
            var line = { time: pre.desc, location: pre.location, id: pre.id };
            pre.schedules.forEach((s) => {
              line[String(s.professor.id)] = s;
            });
            this.tableContent.push(line);
          });
        })
        .catch((err) => {
          this.alerts = "数据错误/Data Error";
          console.log(err);
          this.alerts = err.response.data.detail;
        });
    },
    presetAddClose() {
      this.addPresetDialog = false;
      this.$nextTick(() => {
        this.newPresetDate = null;
        this.newPresetStartTime = null;
        this.newPresetEndTime = null;
      });
    },
    presetAddConfirm() {
      const startTime = new Date(this.newPresetDate + "T" + this.newPresetStartTime);
      const endTime = new Date(this.newPresetDate + "T" + this.newPresetEndTime);
      requests
        .post(`/api/admin/schedule/add`, {
          time_start: startTime,
          time_end: endTime,
          location: this.newLocation,
        })
        .then(() => {
          this.getAllSchedulePreset();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.presetAddClose();
    },
    handleDeletePreset(item) {
      this.deletePreset = item;
      this.deletePresetDialog = true;
    },
    presetDeleteClose() {
      this.$nextTick(() => {
        this.deletePreset = {};
      });
      this.deletePresetDialog = false;
    },
    presetDeleteConfirm() {
      requests
        .post(`/api/admin/schedule/${this.deletePreset.id}/delete`)
        .then(() => {
          this.getAllSchedulePreset();
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.detail));
        });
      this.presetDeleteClose();
    },
    adminFileDownload(token) {
      const url = `/api/admin/download?token=${token}`;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportSchedule() {
      this.waiting = true;
      requests
        .post(`/api/admin/camp/export/schedule`)
        .then((res) => {
          this.adminFileDownload(res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },

    forceFileDownload(res, title) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement("a");
      link.href = url;
      console.log(url);
      link.download = title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    viewPost(item) {
      requests
        .get(`/api/admin/schedule/professor/post/${item.id}`, { responseType: "blob" })
        .then((res) => {
          const ext = mimeDB[res.data.type].extensions[0];
          const fname = `夏令营海报-${item.name}.${ext}`;
          this.forceFileDownload(res, fname);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            alert("未上传海报/Post not uploaded");
          }
        });
    },
  },
};
</script>
